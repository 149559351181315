import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import {
  catchError,
  map,
  shareReplay,
  tap,
  switchMap,
  share,
} from 'rxjs/operators';
import { Floorplan } from './../models/floorplan';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FloorplanService {
  floorPlans!: Floorplan[];
  floorPlansOnDisplay!: Floorplan[];

  constructor(private readonly http: HttpClient) {}

  getFloorplans(): Observable<Floorplan[]> {
    return this.http
      .get<Floorplan[]>(`${environment.API.BASE_URL}/${environment.ordered}`)
      .pipe(
        tap((floorPlans: Floorplan[]) => {
          this.floorPlans = floorPlans;
          this.floorPlansOnDisplay = floorPlans.filter(
            (floorPlan: Floorplan) => floorPlan.onDisplay === true
          );
        }),
        catchError(this.handleError<Floorplan[]>('getFloorplans', []))
      );
  }

  /** filter for onDisplay=TRUE or FALSE */

  //*********************************************************************************/
  //************************return <Floorplans>**************************************/
  //*********************************************************************************/

  // floorplans$ = this.http
  //   .get<Floorplan[]>(`${environment.API.BASE_URL}/${environment.ordered}`)
  //   .pipe(
  //     map((data: Floorplan[]) => {
  //       return data.map((floorplan) => ({
  //         //2 lines below work and were for scully to replace spaces with dash for static pages
  //         // seriesName: floorplan.seriesName.trim().replace(/\s+/g, '-'), //get rid of spaces for pathname
  //         // modelName: floorplan.modelName.trim().replace(/\s+/g, '-'),  //did this for scully

  //         seriesName: floorplan.seriesName.trim(),
  //         modelName: floorplan.modelName.trim(),
  //         floorplanId: floorplan.floorplanId,
  //         floorplanCaption: floorplan.floorplanCaption,
  //         floorplanDescription: floorplan.floorplanDescription,
  //         bedrooms: floorplan.bedrooms,
  //         bathrooms: floorplan.bathrooms,
  //         floors: floorplan.floors,
  //         floorplanUrl: floorplan.floorplanUrl,
  //         thumbnailUrl: floorplan.thumbnailUrl,
  //         customString1: floorplan.customString1,
  //       }));
  //     }),
  //     shareReplay(), //added by Michael.... read up on this
  //     catchError(this.handleError<Floorplan[]>('getFloorplan'))
  //   );

  //*********************************************************************************/
  //************************return <Floorplan>***************************************/
  //*********************************************************************************/
  //service that returns a observable Floorplan when passing in a floorplanId paramater
  getFloorplan(floorplanId): Observable<Floorplan> {
    return this.http
      .get<Floorplan>(
        `${environment.API.BASE_URL}/${environment.fp}/${floorplanId}`
      )
      .pipe(
        //tap((data) => console.log(JSON.stringify(data))),
        catchError(this.handleError<Floorplan>('getFloorplan'))
      );
  }

  /** handle Http operation failed.   */
  private handleError<T>(opertion = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      //TODO: send error to remote logging infrastructure
      this.log('error in getFloorplans');
      console.error(error); //log to console
      this.log(error.message);
      return of(result as T);
    };
  }

  /** Log a message with injected MessageService by creating a wrapper since we call it so much */
  private log(message: string): void {
    /**this.messageService.add(`Mobilehome.service: ${message}`);*/
    console.log(message);
  }
}
